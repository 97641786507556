<template>
  <div class="content">
    <el-row class="bgW" v-if="!clienId">
      <el-col :span="22">
        <el-form class="search" :inline="true" size="mini" :model="search">
          <el-form-item label="姓名/手机号">
            <el-input
              v-model="search.customer_name_or_telephone"
              maxlength="30"
              class="w160"
              placeholder="请输入姓名或手机号"
              clearable
            />
          </el-form-item>
          <el-form-item label="来源">
            <el-select v-model="search.source_id" clearable class="w100">
              <el-option
                v-for="item in options.source_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="客服">
            <el-input
              v-model="search.user_name"
              maxlength="30"
              class="w100"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="定时回访日期">
            <el-date-picker
              v-model="search.yjreturn_visit_time"
              type="date"
              class="w130"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>

            <el-date-picker
              v-model="search.yjreturn_visit_time2"
              type="date"
              class="w130"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="是否回访">
            <el-select v-model="search.is_vist" clearable class="w100">
              <el-option
                v-for="item in options.is_vist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <div class="btnBox">
        <div v-if="clienId"></div>
        <el-button
          type="primary"
          v-if="!clienId && !userInfo.shop_id"
          :disabled="!idList.length"
          class="mb10"
          size="mini"
          @click="hint('导出选中列表','')"
        >导出数据</el-button>
        <div v-if="userInfo.shop_id"></div>
        <!--  -->
      </div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
            @selection-change="handleSelectionChange"
          >
            <ElTableColumn v-if="!clienId && !userInfo.shop_id" type="selection" width="50" />
            <ElTableColumn label="客户名称" prop="customer_name" />
            <ElTableColumn label="手机号" prop="telephone" />
            <ElTableColumn label="性别" prop="sex" />
            <ElTableColumn label="来源" prop="source_name" />
            <ElTableColumn label="门店" prop="shop_name" />
            <ElTableColumn label="护理师" prop="nurse_name" />
            <ElTableColumn label="客服" prop="operator" />
            <ElTableColumn label="预计回访时间" prop="next_visit_time" />
            <ElTableColumn label="操作" fixed="right">
              <template slot-scope="{ row }">
                <span class="theme" @click="tapBtn('客户详情',row)">查 看</span>
              </template>
            </ElTableColumn>
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>

    <!--  -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pagination from "@/components/Pagination";
import {
  timingList,
  clientList,
  addTiming,
  disposeTiming,
  vistExport,
  ssettingList
} from "@/api/client";
class Search {
  customer_name_or_telephone = "";
  source_id = "";
  user_name = "";
  yjreturn_visit_time = "";
  yjreturn_visit_time2 = "";
  is_vist = 1;
}
class VisitDetail {
  customer_id = "";
  shop_id = "";
  yjreturn_visit_time = "";
  yjreturn_visit_content = "";
}
class Rules {
  customer_id = [{ required: true, message: "请选择客户", trigger: "change" }];
  yjreturn_visit_time = [
    { required: true, message: "请选择预约回访日期", trigger: "change" }
  ];
  yjreturn_visit_content = [
    { required: true, message: "请填写预约回访内容", trigger: "blur" }
  ];
}
class Options {
  client = [];
  source_id = [];
  is_vist = [
    {id:1,name:'待回访'},
    {id:2,name:'已回访'},
  ]
}
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  name: "ClientList",
  components: {
    pagination
  },
  props: {
    clienId: {
      type: String,
      default: ""
    },
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      page: new Page(),
      search: new Search(),
      options: new Options(),
      visitDetail: new VisitDetail(),
      rules: new Rules(),
      list: [],
      idList: "",
      visitClient: false,
      loading: false
    };
  },
  filters: {
    statusFilter(val) {
      return ["启用", "禁用"][+val];
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.getOptions();
    this.getList();
  },
  methods: {
    // 获取选项
    getOptions() {
      ssettingList({ page: 1, limit: 100000 }).then(res => {
        let that = this;
        res.list.forEach(function(item) {
          if (item.category_id == 4) {
            that.options.source_id.push(item);
          }
        });
      });
    },
    // 客户列表
    remoteMethod(name) {
      clientList({ page: 1, limit: 100000, customer_name_phone: name }).then(
        res => {
          this.options.client = res.list;
        }
      );
    },
    // 选择客户
    changeCustomer(e) {
      this.options.client.forEach(item => {
        if (e == item.id) {
          this.visitDetail.shop_id = item.shop_id;
        }
      });
    },
    // 获取列表
    getList(option) {
      if (option === "search") this.page = new Page();
      timingList({ ...this.search, ...this.page, customer_id: this.clienId }).then(res => {
        this.list = res.data.list;
        this.page.total = res.data.dataCount;
      });
    },
    // 添加定时回访
    addVistListPo() {
      this.visitClient = true;
      this.visitDetail.yjreturn_visit_time = "";
      this.visitDetail.yjreturn_visit_content = "";
    },
    // 点击处理回访按钮
    tapBtn(id) {
      this.$prompt("", "请填写实际回访内容", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputErrorMessage: "请填写回访内容"
      })
        .then(({ value }) => {
          this.confirm(id, value);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入"
          });
        });
    },
    // 提交
    submitForm(formName) {
      // this.checking();
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.hint("添加该条定时回访", "add");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 提示
    hint(text, type) {
      this.$confirm(`${text},是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (type == "add") {
            this.confirm(type);
          } else {
            this.getExport();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 导出
    getExport() {
      vistExport({ id: this.idList, type: 1 }).then(res => {
        window.open(res.data.url);
      });
    },
    // 确定提交
    confirm(type, val) {
      if (type == "add") {
        addTiming({ ...this.visitDetail }).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.visitClient = false;
          this.getList();
        });
      } else {
        disposeTiming({ id: type, return_visit_content: val }).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.getList();
        });
      }
    },

    // 跳转按钮
    tapBtn(routerName, row) {
      
      const query = {
        id: row.id,
        activeName: "eighth",
        customer_name: row.customer_name,
        search: this.search,
        pageName: "客户列表",
        page:this.page
      };
      
      if(routerName=='客户详情'){
        let route = this.$router.resolve({ name: routerName, query });
        window.open(route.href, '_blank');
      }
    },
    // 改变表格选中状态
    handleSelectionChange(val) {
      this.idList = val.map(i => {
        return i.id;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.mainColor {
  color: #2dc0a3;
}
.content {
  height: calc(100% - 56px);
  overflow-y: auto;
}
.bgW {
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 20px;
}

.el-form-item {
  margin: 0px 20px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 0px 10px 30px;
  .btnBox {
    display: flex;
    justify-content: space-between;
  }
  .entryBox {
    height: 600px;
    overflow-y: scroll;
    .entryRow1 {
      display: flex;
      .item-circle {
        width: 18px;
        height: 18px;
        z-index: 2;
        border-radius: 9px;
        background-color: white;
        border: 5px solid #a5ecd7;
      }
      .clientName {
        line-height: 30px;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .entryRow2 {
      color: #999;
      margin: 10px 0 0 20px;
      display: flex;
      font-size: 14px;
    }
    .entryRow3 {
      color: #444;
      margin: 10px 0 20px;
      font-size: 15px;
    }
  }
  ::-webkit-scrollbar {
    display: none;
  }
}
.frame {
  .el-form-item {
    margin: 0px 20px 26px 0;
  }
}
.footerBtn {
  text-align: right;
}
</style>
